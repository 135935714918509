/* Header avec image de fond */
.about-us .header {
  position: relative;
  height: 510px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.content{
  background-color: #E6E7EB;
}

.about-us .section-top{
  background-color: #E6E7EB;
  border-radius: 10px;
  width:80%;
  margin-left:10%;
  margin-bottom: 2%;
  padding:0;
}

.about-us .section-title {
  text-align: center;
  font-size: 3em; 
  color: #061237;
  padding:20px;
}

.about-us .section-top .section-title .highlight {
  color: #3972E5; 
}

.about-us .section-description {
  text-align: center; 
  font-size: 1.2em;
  color: #666; 
  margin: 0 auto;
  width: 80%; 
  line-height: 1.8;
  padding-top: 1%; 
  padding-bottom: 3%;
}

/* A vision */
.about-us .container-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  height: 800px;
  background-color: white;
  position: relative;
  padding: 4rem 2rem;
}


.about-us .textblock-right {
  width: 50%;
  font-family: 'PPMori';
  margin-left: -40%;
  margin-top: -8%;
}

.about-us .textblock-right .title-right {
  text-align: center;
  font-size: 50px;
  color:#061237;
}

.about-us .textblock-right .sub-title-right {
  text-align: center;
  font-size: 1.2rem;
  color:#3972E5;
  margin-bottom: 3rem;
}

.about-us .textblock-right p {
  text-align: justify;
  line-height: 150%;
  width:80%;
  margin-bottom: 1.3rem;
  margin-left: 6rem;
  font-size: 24px;
}

.about-us .container-right img {
 position:absolute;
 height:auto;
 width: 40%;
 top:10%;
 right:5%;
 box-shadow: 5px 4px 10px rgba(37, 37, 37, 0.15);
}

/* A mission */
.about-us .container-left {
  width:100%;
  margin:auto;
  height:850px;
  background-color: #061237;
  position: relative;
  padding: 4rem 2rem;
}

.about-us .textblock-left {
  width:100%;
  font-family:'PPMori';
  padding-left : 40%;
  margin-top: 1%;

}

.about-us .textblock-left .title-left {
  text-align: center;
  font-size: 50px;
  color:#fff;
}

.about-us .textblock-left .sub-title-left {
  text-align: center;
  font-size: 1.2rem;
  color:#3972E5;
  margin-bottom: 4rem;
}

.about-us .textblock-left p {
  text-align: justify;
  color:#fff;
  width:80%;
  margin-bottom: 1.3rem;
  line-height: 150%;
  margin-bottom: 1.3rem;
  margin-left: 6rem;
  font-size: 24px;
}

.about-us .container-left img {
 position:absolute;
 height:auto;
 width: 40%;
 top:20%;
 left:2%;
 box-shadow: 5px 4px 10px rgba(37, 37, 37, 0.15);
}

/* An ambition */
.about-us .container-last {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  height: 800px;
  background-color: #E6E7EB;
  position: relative;
  padding: 4rem 2rem;
  
}

.about-us .textblock-last {
  width: 50%;
  font-family: 'PPMori';
  margin-left: -40%;
  margin-top: 1%;

}

.about-us .textblock-last .title-last {
  text-align: center;
  font-size: 50px;
  color:#061237;
}


.about-us .textblock-last .sub-title-last {
  text-align: center;
  font-size: 1.2rem;
  color:#3972E5;
  margin-bottom: 3rem;
}

.about-us .textblock-last p {
  text-align: justify;
  line-height: 150%;
  width:80%;
  margin-bottom: 1.3rem;
  margin-left: 6rem;
  font-size: 24px;
}

.about-us .container-last img {
 position:absolute;
 height:auto;
 width: 30%;
 top:14%;
 right:10%;
 box-shadow: 5px 4px 10px rgba(37, 37, 37, 0.15);
}

/* Animations */
.about-us .container-left .textblock-left .title-left,
.about-us .container-right .textblock-right .title-right,
.about-us .container-last .textblock-last .title-last,
.about-us .container-left .textblock-left .sub-title-left,
.about-us .container-right .textblock-right .sub-title-right,
.about-us .container-last .textblock-last .sub-title-last{
  opacity: 0; 
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; ; 
}

.about-us .container-left.is-inViewport .textblock-left .title-left,
.about-us .container-right.is-inViewport .textblock-right .title-right,
.about-us .container-last.is-inViewport .textblock-last .title-last{
  opacity: 1; 
  transform: translateX(0);
  animation: slidein-right 0.5s ease-out; 
}

.about-us .container-left.is-inViewport .textblock-left .sub-title-left,
.about-us .container-right.is-inViewport .textblock-right .sub-title-right,
.about-us .container-last.is-inViewport .textblock-last .sub-title-last{
  opacity: 1; 
  transform: translateX(0);
  animation: slidein-left 0.5s ease-out; 
}

@keyframes slidein-left {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slidein-right {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}


.about-us .header .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.about-us .header .title {
  color: white;
  font-family: 'PPMori';
  padding-left: 71%;
  font-size: 2.8em;
  padding-top: 50px;
  z-index: 1; 
}

.about-us .bottom-section {
  background-color: #061237;
  text-align: center;
}


.about-us .video_holder {
  width: 100%;
  height: auto;
  max-height: 100px;
  overflow: hidden;
}

.about-us .video_holder video {
width: 100%; 
height: auto; 
}

/* Requêtes média pour resizing */
@media (max-width: 1500px) {
  .about-us .section-top{
    margin-bottom: 15%;
  }
  
  .about-us .container-right{
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .about-us .container-left{
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .about-us .container-last{
    flex-direction: column-reverse;
    align-items: center;
    height: auto;
  }

  .about-us .textblock-right,
  .about-us .textblock-last,
  .about-us .textblock-risk-management,
  .about-us .treasury-management-sp-textblock {
    width: 90%;
    margin-left:0;
    margin-top:0;
    text-align: center;
  }

  
  .about-us .textblock-left {
    width:100%;
    font-family:'PPMori';
    padding-left : 0%;
    padding-top: 0%;
    margin-top: 0%;
    text-align: center;
  }

  .about-us .textblock-right p,
  .about-us .textblock-last p {
    margin-left: 7%;
    text-align: center;
    font-size: 16px;
  }

  .about-us .textblock-left p {
    text-align: center;
    width:80%;
    margin-left: 10%;
    font-size: 16px;
  }
  

  .about-us .container-right img,
  .about-us .container-last img{
    position: relative;
    width: 90%;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  
  .about-us .container-left img{
    position: relative;
    width: 90%;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto; 
  }
}

@media (max-width: 767px) {

  .about-us .textblock-left .title-left,
  .about-us .textblock-right .title-right,
  .about-us .textblock-last .title-last,
  .about-us .textblock-left .sub-title-left,
  .about-us .textblock-right .sub-title-right,
  .about-us .textblock-last .sub-title-last {
    text-align: center; /* Centrer le titre et le sous-titre */
    margin-left: auto;
    margin-right: auto;
  }
  
  .about-us .textblock-left p,
  .about-us .textblock-right p,
  .about-us .textblock-last p {
    width: 90%; /* Ajuster la largeur à celle des images */
    margin: 0 auto; /* Centrer les blocs de texte */
    text-align: justify; /* Justifier le texte */
    line-height: 1.4; /* Réduire l'interligne pour les petits écrans */
  }

  .about-us .container-left img,
  .about-us .container-right img,
  .about-us .container-last img {
    width: 90%;
    margin: 0 auto;
  }

  .about-us .section-description {
    text-align: justify; /* Justifier le texte */
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4; /* Réduire l'interligne */
    width: 90%; /* Ajuster la largeur du texte */
  }
}