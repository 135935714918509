/* Styles des cartes de redirections */
.content-home{
  margin-top:100px;
}
.content-home .card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 100px;
}
  
.content-home .card {
  width: 500px;
  border-left: 2px solid #E6E7EB;
  overflow: hidden;
  margin: 10px 0px 80px 20px;
  display: flex;
  flex-direction: column;
}
  
.content-home .card-title {
  height:80px;
}
  
.content-home .card-content {
  padding: 16px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
  
.content-home .card-content h3 {
  font-size: 28px;
  margin-bottom: 25px;
  flex-shrink: 0;
}
  
.content-home .card-content p {
  color: #666;
  font-size: 19px;
  line-height: 1.3;
  margin: 0;
  flex: 1;
}
  
.content-home .card img {
  width: 90%;
  height: 300px;
  margin-left: 16px;
  border-radius: 4px;
  transition: all 0.1s linear;
}


.content-home .card-clickable a{
  text-decoration: none;
}

.content-home .card-clickable a p{
  font-size: 20px;
  color: #3972E5;
  margin: 0px 0px 20px 20px;
  transition: color 0.2s linear;
}

.content-home .card-clickable{
  margin-top:20px;
}
.hover-underline-animation{
  display: inline-block;
  position: relative;
  color: #3972E5;
}

/* Animations cartes */
.hover-underline-animation::after {
  content: '';
  position: absolute;
  transform: scaleX(0);
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #3972E5;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.card-clickable:hover .hover-underline-animation::after,
.card-clickable:hover img {
  transform: scaleX(1); 
  transform-origin: bottom left;
  color: #3972E5; 
}

.card-clickable:hover img {
  transform: scale(1.01); 
  cursor: pointer;
}

/* Prefooter */
.content-home .bottom-section {
  background-color: #061237;
  text-align: center;
}
  
.content-home .video_holder {
  width: 100%;
  height: auto;
  max-height: 100px;
  overflow: hidden;
}
  
.content-home .video_holder video {
  width: 100%;
  height: auto; 
}
