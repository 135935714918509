/* Partie sur la vidéo de fond */
.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.hero-container video {
  width: 100%;
  height: 100%;
  filter: brightness(90%);
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-container .top {
  display: inline-block;
  width: 4em;
  height: 4em;
  border: 0.5em solid #333;
  border-radius: 50%;
  margin-right: 0.75em;
}

.hero-container .top:after {
  content: '';
	display: inline-block;
  margin-top: 1.4em;
  width: 1.4em;
  height: 1.4em;
  border-top: 0.5em solid #333;
  border-right: 0.5em solid #333;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hero-container section {
  position: absolute;
  top: 87%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer
}

.overlay {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));
    filter: brightness(2);
    z-index: 2;
    pointer-events: none; /* Permet de cliquer à travers le dégradé */
}

@keyframes fadeOut {
  0% {
    opacity: 1; /* Opacité initiale de 100% (noir) */
  }
  100% {
    opacity: 0; /* Opacité finale de 0% (transparent) */
  }
}

/* Partie sur le bouton de scroll */
#learn-more {
  z-index: 2;
}

#learn-more a {
  color: #fff;
}

#learn-more h1 {
  color: #fff;
}

#learn-more a span {
  position: relative;
  display: inline-block; 
  margin-top: 0px;
  width: 30px;
  height: 30px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: rotate(-45deg);
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}