/* Header avec image de fond */
.alternative-solutions .header {
  position: relative;
  height: 510px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.alternative-solutions .header .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Bandeau */
.alternative-solutions .headband {
  background-color: #061237;
  color:#fff;
  font-family: 'PPMori';
  font-size: 20px;
  font-style: italic;
  text-align: center;
  line-height: 25px;
}

.alternative-solutions .headband h4{
  padding:1%;
}

.alternative-solutions .headband a:visited {
  color:#fff;
}


/* Open-end Funds */
.alternative-solutions .open-end-fund {
  background-color:#fff;
  margin-bottom: 6%;
}

.alternative-solutions .open-end-fund-header {
  display: flex;
  align-items: center;
}

.alternative-solutions .title-open-end-fund {
  margin:50px;
  padding-left: 50%;
}

.alternative-solutions .title-open-end-fund h1{
  font-size: 50px;
  color:#061237;
  padding-top: 3%;
}

.alternative-solutions .title-open-end-fund h1 .highlight {
  color: #3972E5; 
}

.alternative-solutions .text-open-end-fund {
  margin-right: -40%;
  padding-left: 8%;
  padding-top: 7%;
  width:45%;
  line-height: 2.3;
  font-size: 20px;
  text-align: justify;
}

.alternative-solutions .text-open-end-fund b{
  color:#061237;
  font-weight: 800;
}

.alternative-solutions .open-end-fund-bottom {
  display: flex;
  align-items: center;
  padding-top: 2%;
}

.alternative-solutions .open-end-fund-bottom img{
  width:50%;
  height: auto;
  padding-left: 10%;
}

.alternative-solutions .open-end-fund-bottom .text-open-end-fund b{
  color:#061237;
  font-style: underline;
  font-weight: 800;
}

.alternative-solutions .open-end-fund-bottom .text-open-end-fund p{
  margin-top:-13%;
  padding-bottom:17%;
}



/* Dedicated Funds */
.alternative-solutions .dedicated-fund {
  background-color:#E6E7EB;
  margin-bottom: 6%;
}

.alternative-solutions .dedicated-fund-header {
  display: flex;
  align-items: center;
}

.alternative-solutions .title-dedicated-fund {
  margin:0px;
  padding-left: 15%;
  padding-right: 3%;
}

.alternative-solutions .text-dedicated-fund {
  padding-left: 5%;
  padding-top: 7%;
  width:45%;
  line-height: 2.3;
  font-size: 20px;
  text-align: justify;
}

.alternative-solutions .title-dedicated-fund h1{
  font-size: 50px;
  text-align:left;
  color:#061237;
}

.title-dedicated-fund h1 .highlight {
  color: #3972E5; 
}

.alternative-solutions .container-dedicated-fund {
  width:100%;
  height:50%;
  padding: 0 15% 5%;
}

.alternative-solutions .container-dedicated-fund h1{
  text-align: center;
  padding-top: 7%;
  margin-bottom: 60px;
  font-weight: 600;
  position:relative;
}

.alternative-solutions .container-dedicated-fund h1::after{
  content:'';
  background: #3972E5;
  width:100px;
  height: 5px;
  position: absolute;
  bottom:-10px;
  left:50%;
  transform: translateX(-50%);
}

.alternative-solutions .row{
  display:grid;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
  grid-gap:50px;
  background-color:#E6E7EB;
}

.alternative-solutions .service{
  text-align: center;
  padding : 50px 30px;
  border-left:solid 1px #061237;
}

.alternative-solutions .service h2{
  font-weight:700;
  margin-bottom: 30px;
  font-size: 28px;
}

.alternative-solutions .service p{
  font-size: 20px;
}

.alternative-solutions .header .title {
  color: white;
  font-family: 'PPMori';
  padding-left: 71%;
  font-size: 2.8em;
  padding-top: 50px;
  z-index: 1; 
}

/* Strategies & classes */
.alternative-solutions .title-cards {
  margin:50px;
  padding-left: 100px;
}

.alternative-solutions .title-cards h1{
  font-size: 50px;
  text-align:left;
  color:#061237;
}

.title-cards h1 .highlight {
  text-align:left;
  color: #3972E5; 
}

.alternative-solutions .card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 100px;
}

.alternative-solutions .card {
  width: 375px;
  border-left: 2px solid #E6E7EB;
  overflow: hidden;
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.alternative-solutions .card-title {
  height:100px;
}

.alternative-solutions .card img {
  width: 90%;
  height: 220px;
  margin-left: 16px;
  border-radius: 4px;
}

.alternative-solutions .card-content {
  padding: 16px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.alternative-solutions .card-content h3 {
  font-size: 28px;
  margin-bottom: 25px;
  flex-shrink: 0;
}

.alternative-solutions .card-content p {
  color: #666;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  flex: 1;
}

/* Prefooter */
.alternative-solutions .bottom-section {
  background-color: #061237;
  text-align: center;
}

.alternative-solutions .video_holder {
  width: 100%;
  height: auto;
  max-height: 100px;
  overflow: hidden;
}

.alternative-solutions .video_holder video {
width: 100%;
height: auto; 
}

/* Animations */
.alternative-solutions .title-cards,
.alternative-solutions .open-end-fund .title-open-end-fund,
.alternative-solutions .dedicated-fund .title-dedicated-fund,
.alternative-solutions .dedicated-fund .text-dedicated-fund{
  opacity: 0; 
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; ; 
}


.alternative-solutions .open-end-fund .title-open-end-fund.is-inViewport,
.alternative-solutions .dedicated-fund .title-dedicated-fund.is-inViewport,
.alternative-solutions .dedicated-fund .text-dedicated-fund.is-inViewport,
.alternative-solutions .title-cards.is-inViewport{
  opacity: 1; 
  transform: translateX(0);
  animation: slidein-left 0.5s ease-out; 
}

@keyframes slidein-left {
  0% {
    transform: translateX(-5%);
  }
  100% { 
    transform: translateX(0);
  }
}

/* Requêtes média pour resizing */
@media (max-width: 1100px) {

  .alternative-solutions .open-end-fund-header {
    flex-direction: column-reverse;
    text-align: left;
  }

  .alternative-solutions .open-end-fund {
    margin-bottom: 0%;
  }
  
  .alternative-solutions .title-open-end-fund h1 {
    font-size: 40px; 
  }

  .alternative-solutions .title-open-end-fund {
    padding-left: 0%;
    padding-top: 2%;
  }

  .alternative-solutions .open-end-fund-bottom {
    flex-direction: column;
  }
  .alternative-solutions .text-open-end-fund {
    text-align: center;
    margin-right: 0%;
    padding-left: 0%;
    padding-top: 0%;
    padding-bottom: 90px;
    width: 90%;
  }

  .alternative-solutions .open-end-fund-bottom .text-open-end-fund {
    text-align: center;
    margin-right: 0%;
    padding-left: 0%;
    padding-top: 150px;
    padding-bottom: 0px;

  }

  .alternative-solutions .container-dedicated-fund {
    width:100%;
    padding: 0 15% 150px;
  }
  .alternative-solutions .dedicated-fund-header {
    flex-direction: column;
    text-align: left;
  }

  .alternative-solutions .dedicated-fund-header .title-dedicated-fund {
    padding-left: 0%;
  }

  .alternative-solutions .text-dedicated-fund {
    text-align: center;
    padding-left: 0%;
    padding-top: 30px;
    width: 85%;
  }

  .alternative-solutions .title-cards {
    margin-top:0%;
    padding-top: 100px;
    margin:50px;
    padding-left: 0px;
  }

  .alternative-solutions .open-end-fund-bottom img {
    width: 90%;
    padding-left: 0%;
  }

  .alternative-solutions .title-dedicated-fund {
    margin: 50px;
  }

  .alternative-solutions .text-open-end-fund,
  .alternative-solutions .open-end-fund-bottom .text-open-end-fund p {
    font-size: 16px;
  }

  .alternative-solutions .dedicated-fund .text-dedicated-fund,
  .alternative-solutions .dedicated-fund .service p {
    font-size: 16px;
  }

  .alternative-solutions .card-content p {
    font-size: 16px;
  }

  .alternative-solutions .headband {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .alternative-solutions .text-open-end-fund {
    text-align: justify; /* Justification du texte sur mobile */
  }

  .alternative-solutions .open-end-fund-bottom .text-open-end-fund {
    text-align: justify; /* Justification du texte sur mobile */
  }

  .alternative-solutions .dedicated-fund-header .text-dedicated-fund {
    text-align: justify; /* Justification du texte sur mobile */
  }

  .alternative-solutions .title-open-end-fund h1,
  .alternative-solutions .title-dedicated-fund h1,
  .alternative-solutions .title-cards h1 {
    font-size: 30px; /* Ajustement de la taille des titres */
  }

  /* Diminution de la taille des sous-titres sur mobile */
  .alternative-solutions .container-dedicated-fund h1,
  .alternative-solutions .service h2 {
    font-size: 20px; /* Ajustement de la taille des sous-titres */
  }

  /* Diminution de la taille des paragraphes dans les cartes */
  .alternative-solutions .card-content p {
    font-size: 14px; /* Ajustement de la taille du texte des cartes */
  }

  /* Ajustement des paragraphes dans les fonds ouverts et dédiés */
  .alternative-solutions .text-open-end-fund p,
  .alternative-solutions .text-dedicated-fund p {
    font-size: 16px; /* Réduction de la taille des paragraphes pour une meilleure lisibilité */
  }
}