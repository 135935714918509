/* Corps */
body{
    margin:0;
    font-family: 'PPMori';
    position: relative;
}

*{
    box-sizing: border-box;
}

/* Location section */
.locations{
    margin-top: 80px;
}

.locations .bottom-section {
    background-color: #061237;
    text-align: center;
  }
  
  
.locations .video_holder {
    width: 100%;
    height: auto;
    max-height: 100px;
    overflow: hidden;
  }
  
.locations .video_holder video {
  width: 100%; 
  height: auto; 
  }

  .locations .card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 150px;
    margin-bottom: 100px;
  }
  
  .locations .card {
    width: 500px;
    border-left: 2px solid #E6E7EB;
    overflow: hidden;
    margin: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .locations .card-title {
    height:80px;
  }
  
  .locations .card img {
    width: 90%;
    height: 320px;
    margin-left: 16px;
    border-radius: 4px;
  }
  
  .locations .card-content {
    padding: 16px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .locations .card-content h3 {
    font-size: 28px;
    margin-bottom: 25px;
    flex-shrink: 0;
  }
  
  .locations .card-content p {
    color: #666;
    font-size: 18px;
    line-height: 1.3;
    margin: 0;
    flex: 1;
  }