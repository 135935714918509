@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide > div {
    margin : 0 10px;
}

.slick-prev:before,
.slick-next:before {
  color: #3972E5;
}