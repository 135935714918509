@font-face {
  font-family: 'PPMori';
  src: url('./fonts/PPMori-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PPMori';
  src: url('./fonts/PPMori-Bold.woff') format('woff');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'PPMori';
  src: url('./fonts/PPMori-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'PPMori';
  src: url('./fonts/PPMori-Light.woff') format('woff');
  font-weight: 200;
  font-style: light;
}

/* Styles par défaut pour les grandes tailles d'écran */
body {
  font-family: 'PPMori', sans-serif;
  font-size: 16px; /* Taille de police par défaut */
}

h1, h2, h3, p, a, li {
  font-family: 'PPMori', sans-serif;
}

/* Réduction des tailles de police pour les appareils mobiles */
@media screen and (max-width: 768px) {
  body {
      font-size: 13px; /* Taille de police réduite pour mobile */
  }

  h1 {
      font-size: 2.2em; /* Ajuster la taille des titres */
  }

  h2 {
      font-size: 1.8em;
  }

  h3 {
      font-size: 1.5em;
  }

  p, a, li {
      font-size: 0.9em; /* Réduire la taille des paragraphes, liens et listes */
  }
}