/* Header avec image de fond */
.invest-solutions .header {
  position: relative;
  height: 510px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.invest-solutions .header .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Partie sur le conseil */
.invest-solutions .advisory-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  height: 800px;
  background-color: #fff;
  position: relative;
  padding: 4rem 2rem;
}

.invest-solutions .advisory-container .textblock-advisory {
  width: 50%;
  font-family: 'PPMori';
  margin-left: -40%;
  margin-top: 3%;
}

.invest-solutions .advisory-container .textblock-advisory .title {
  text-align: center;
  margin-bottom: 1rem;
}

.invest-solutions .advisory-container .textblock-advisory .title h1 {
  font-size: 50px;
  color: #061237;
}

.invest-solutions .advisory-container .textblock-advisory .title h1 .highlight {
  color: #3972e5;
}

.invest-solutions .advisory-container .textblock-advisory .sub-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 18px;
  color: #3972e5;
}

.invest-solutions .advisory-container .textblock-advisory p {
  text-align: justify;
  line-height: 45px;
  width: 80%;
  margin-bottom: 1.3rem;
  margin-left: 6rem;
  font-size: 24px;
}

.invest-solutions .advisory-container img {
  position: absolute;
  height: auto;
  width: 37%;
  max-width: 800px;
  top: 17%;
  right: 5%;
}

.invest-solutions .card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 100px;
}

.invest-solutions .card {
  width: 400px;
  border-left: 2px solid #e6e7eb;
  overflow: hidden;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.invest-solutions .card-title {
  height: 80px;
}

.invest-solutions .card-content {
  padding: 16px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.invest-solutions .card-content h3 {
  font-size: 28px;
  margin-bottom: 25px;
  flex-shrink: 0;
}

.invest-solutions .card-content p {
  color: #666;
  font-size: 19px;
  line-height: 1.3;
  margin: 0;
  flex: 1;
}

.invest-solutions .card img {
  width: 90%;
  height: 250px;
  margin-left: 16px;
  border-radius: 4px;
}

/* Styles pour les cartes des sections supplémentaires */
.invest-solutions .section-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 100px;
}

.invest-solutions .section-card {
  width: 400px;
  border-left: 2px solid #e6e7eb;
  overflow: hidden;
  margin: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.invest-solutions .section-card:hover {
  transform: scale(1.05);
}

.invest-solutions .section-card .card-content {
  padding: 16px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.invest-solutions .section-card .card-content h3 {
  font-size: 28px;
  margin-bottom: 25px;
  flex-shrink: 0;
}

.invest-solutions .section-card .card-content p {
  color: #666;
  font-size: 19px;
  line-height: 1.3;
  margin: 0;
  flex: 1;
}

.invest-solutions .section-card img {
  width: 90%;
  height: 250px;
  margin-left: 16px;
  border-radius: 4px;
}

/* Treasury-management */
.invest-solutions .treasury-management-container {
  background-color: #e6e7eb;
  padding-bottom: 100px;
}

.invest-solutions .treasury-management-header {
  display: flex;
  align-items: center;
}

.invest-solutions .treasury-management-title {
  margin: 0px;
  padding-left: 15%;
  padding-right: 3%;
  padding-top: 80px;
}

.invest-solutions .treasury-management-text {
  padding-left: 5%;
  padding-top: 7%;
  width: 46%;
  line-height: 2.3;
  font-size: 24px;
  text-align: justify;
}

.invest-solutions .treasury-management-title h1 {
  font-size: 50px;
  text-align: left;
  color: #061237;
}

.treasury-management-title h1 .highlight {
  color: #3972e5;
}

/* Structured products */
.invest-solutions .treasury-management-sp-container,
.invest-solutions .treasury-management-amc-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  position: relative;
  padding: 4rem 2rem;
}

.invest-solutions .treasury-management-sp-textblock {
  width: 60%;
  font-family: 'PPMori';
  margin-left: -40%;
  margin-top: 3%;
}

.invest-solutions .treasury-management-sp-textblock .title,
.invest-solutions .treasury-management-amc-textblock .title {
  text-align: center;
}

.invest-solutions .treasury-management-amc-textblock .title {
  margin-bottom: 3rem;
}

.invest-solutions .treasury-management-sp-textblock .title {
  margin-bottom: 1rem;
}

.invest-solutions .treasury-management-sp-textblock .sub-title,
.invest-solutions .treasury-management-amc-textblock .sub-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 18px;
  color: #3972e5;
}

.invest-solutions .treasury-management-sp-textblock .title h2,
.invest-solutions .treasury-management-amc-textblock .title h2 {
  font-size: 30px;
  color: #061237;
}

.invest-solutions .treasury-management-sp-textblock p,
.invest-solutions .treasury-management-amc-textblock p {
  text-align: justify;
  line-height: 45px;
  width: 80%;
  margin-bottom: 1.3rem;
  margin-left: 6rem;
  font-size: 22px;
}

.invest-solutions .treasury-management-sp-container img {
  position: absolute;
  height: auto;
  width: 37%;
  max-width: 650px;
  top: 21%;
  right: 5%;
}

/* AMC */
.invest-solutions .treasury-management-amc-textblock {
  width: 50%;
  font-family: 'PPMori';
  margin-left: 40%;
  margin-top: 3%;
}

.invest-solutions .treasury-management-amc-container img {
  position: absolute;
  height: auto;
  width: 37%;
  max-width: 650px;
  top: 25%;
  left: 5%;
}

/* Analyse de portefeuille */
.invest-solutions .portfolio-analysis-container {
  background-color: #061237;
  padding-bottom: 40px;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-header {
  display: flex;
  align-items: center;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-header .title {
  margin: 0px;
  padding-left: 15%;
  padding-right: 3%;
  padding-top: 80px;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-header .title h1 {
  font-size: 50px;
  text-align: left;
  color: #fff;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-header .title .highlight {
  color: #3972e5;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-header .text {
  padding-left: 5%;
  padding-top: 7%;
  width: 45%;
  line-height: 2.3;
  font-size: 22px;
  text-align: justify;
  color: #fff;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-services {
  width: 100%;
  height: 50%;
  padding: 0 15% 5%;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-services .row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  padding-top: 70px;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-services .row .service {
  text-align: center;
  padding: 50px 30px;
  border-left: solid 1px #fff;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-services .row .service h2 {
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 28px;
  color: #fff;
}

.invest-solutions .portfolio-analysis-container .portfolio-analysis-services .row .service p {
  font-size: 20px;
  color: #e6e7eb;
}

/* Management du risque */
.invest-solutions .risk-management-container {
  background-color: #fff;
}

.invest-solutions .risk-management-container .risk-management-header {
  display: flex;
  align-items: center;
}

.invest-solutions .risk-management-container .risk-management-header .title {
  margin: 0px;
  padding-left: 7%;
  padding-right: 3%;
  padding-top: 130px;
}

.invest-solutions .risk-management-container .risk-management-header .title h1 {
  font-size: 50px;
  text-align: left;
  color: #061237;
}

.invest-solutions .risk-management-container .risk-management-header .title .highlight {
  color: #3972e5;
}

.invest-solutions .risk-management-container .risk-management-header .text {
  margin-left: 12%;
  padding-top: 7%;
  width: 46%;
  line-height: 2.3;
  font-size: 22px;
  text-align: justify;
  color: black;
}

.invest-solutions .risk-management-container .risk-management-services {
  width: 100%;
  height: 50%;
  padding: 0 15% 10px;
}

.invest-solutions .risk-management-container .risk-management-services .row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  padding-top: 70px;
}

.invest-solutions .risk-management-container .risk-management-services .row .service {
  text-align: center;
  padding: 50px 30px;
  border-left: solid 1px #e6e7eb;
}

.invest-solutions .risk-management-container .risk-management-services .row .service h2 {
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 28px;
  color: #061237;
}

.invest-solutions .risk-management-container .risk-management-services .row .service p {
  font-size: 20px;
  color: black;
}

/* Animations */
.invest-solutions .advisory-container .textblock-advisory .title,
.invest-solutions .advisory-container img,
.invest-solutions .treasury-management-container .treasury-management-title,
.invest-solutions .treasury-management-container .treasury-management-text,
.invest-solutions .treasury-management-container .treasury-management-sp-container img,
.invest-solutions .treasury-management-container .treasury-management-amc-container img,
.invest-solutions .portfolio-analysis-container .portfolio-analysis-header .title,
.invest-solutions .portfolio-analysis-container .portfolio-analysis-header .text,
.invest-solutions .risk-management-container .risk-management-header .title,
.invest-solutions .risk-management-container .risk-management-header .text {
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.invest-solutions .advisory-container .textblock-advisory .title.is-inViewport,
.invest-solutions .treasury-management-container .treasury-management-title.is-inViewport,
.invest-solutions .treasury-management-container .treasury-management-text.is-inViewport,
.invest-solutions .treasury-management-container .treasury-management-amc-container img.is-inViewport,
.invest-solutions .portfolio-analysis-container .portfolio-analysis-header .title.is-inViewport,
.invest-solutions .portfolio-analysis-container .portfolio-analysis-header .text.is-inViewport {
  opacity: 1;
  transform: translateX(0);
  animation: slidein-left 0.5s ease-out;
}

.invest-solutions .advisory-container img.is-inViewport,
.invest-solutions .treasury-management-container .treasury-management-sp-container img.is-inViewport,
.invest-solutions .risk-management-container .risk-management-header .title.is-inViewport,
.invest-solutions .risk-management-container .risk-management-header .text.is-inViewport {
  opacity: 1;
  transform: translateX(0);
  animation: slidein-right 0.5s ease-out;
}

@keyframes slidein-left {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slidein-right {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Section finale avec vidéo en fond de logo */
.invest-solutions .bottom-section {
  background-color: #061237;
  text-align: center;
  margin-top: -1px;
  padding: 50px 0;
}

.invest-solutions .logo_mask {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.invest-solutions .video_holder {
  width: 100%;
  height: auto;
  max-height: 100px;
  overflow: hidden;
}

.invest-solutions .video_holder video {
  width: 100%;
  height: auto;
}

/* Requêtes média pour le responsive design */
@media (max-width: 1500px) {
  .invest-solutions .treasury-management-title h1,
  .invest-solutions .textblock-advisory .title h1,
  .invest-solutions .portfolio-analysis-container .portfolio-analysis-header .title h1,
  .invest-solutions .risk-management-container .risk-management-header .title h1 {
    font-size: 40px;
  }

  .invest-solutions .advisory-container .textblock-advisory .sub-title,
  .invest-solutions .treasury-management-sp-textblock .sub-title,
  .invest-solutions .treasury-management-amc-textblock .sub-title {
    font-size: 16px;
  }

  .invest-solutions .advisory-container,
  .invest-solutions .treasury-management-sp-container {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .invest-solutions .treasury-management-amc-container {
    flex-direction: column-reverse;
    align-items: center;
    height: auto;
  }

  .invest-solutions .advisory-container .textblock-advisory {
    width: 100%;
    margin-left: 0px;
    font-size: 16px;
  }

  .invest-solutions .advisory-container .textblock-advisory p {
    margin-left: 30px;
    text-align: center;
    font-size: 16px;
  }

  .invest-solutions .advisory-container .textblock-advisory .title {
    margin-left: 0px;
  }

  .invest-solutions .textblock-risk-management,
  .invest-solutions .treasury-management-text,
  .invest-solutions .treasury-management-sp-textblock {
    width: 100%;
    margin-left: 0%;
    text-align: center;
    font-size: 16px;
  }

  .invest-solutions .treasury-management-amc-container {
    width: 100%;
    margin-left: -22%;
    text-align: center;
    font-size: 16px;
  }

  .invest-solutions .treasury-management-amc-textblock {
    width: 90%;
    margin-left: 25%;
    font-size: 16px;
  }

  .invest-solutions .treasury-management-amc-textblock .title {
    margin-left: 33%;
  }

  .invest-solutions .textblock-advisory p,
  .invest-solutions .textblock-risk-management p,
  .invest-solutions .treasury-management-sp-textblock p {
    margin-left: 7%;
    text-align: center;
    font-size: 16px;
  }

  .invest-solutions .treasury-management-amc-textblock p {
    margin-left: 25%;
    text-align: center;
    font-size: 16px;
  }

  .invest-solutions .advisory-container img,
  .invest-solutions .treasury-management-sp-container img {
    position: relative;
    width: 70%;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .invest-solutions .treasury-management-amc-container img {
    position: relative;
    width: 70%;
    top: 0;
    left: 25%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .invest-solutions .treasury-management-header {
    flex-direction: column;
    width: 90%;
    margin-left: 0;
  }

  .invest-solutions .treasury-management-text {
    padding-top: 30px;
    padding-bottom: 80px;
    width: 90%;
    text-align: center;
  }

  .invest-solutions .portfolio-analysis-container .portfolio-analysis-services {
    width: 100%;
    padding: 50px 50px 150px;
  }

  .invest-solutions .portfolio-analysis-container .portfolio-analysis-header {
    flex-direction: column;
    text-align: left;
  }

  .invest-solutions .portfolio-analysis-container .portfolio-analysis-header .title {
    padding-left: 10%;
  }

  .invest-solutions .portfolio-analysis-container .portfolio-analysis-header .text {
    padding-left: 0%;
    padding-top: 30px;
    padding-bottom: 0px;
    width: 80%;
    font-size: 16px;
  }

  .invest-solutions .risk-management-container .risk-management-services {
    width: 100%;
    padding: 50px 50px 150px;
  }

  .invest-solutions .risk-management-container .risk-management-header {
    flex-direction: column-reverse;
    text-align: left;
  }

  .invest-solutions .risk-management-container .risk-management-header .text {
    padding-top: 30px;
    padding-bottom: 0px;
    width: 80%;
    margin-left: 0;
    font-size: 16px;
  }

  .invest-solutions .card p {
    font-size: 16px;
  }

  .invest-solutions
    .portfolio-analysis-container
    .portfolio-analysis-services
    .row
    .service
    p,
  .invest-solutions
    .risk-management-container
    .risk-management-services
    .row
    .service
    p {
    font-size: 16px;
  }

  .invest-solutions
    .portfolio-analysis-container
    .portfolio-analysis-services
    .row
    .service
    h2,
  .invest-solutions
    .risk-management-container
    .risk-management-services
    .row
    .service
    h2 {
    font-size: 20px;
  }
}

/* Ajustements pour le responsive des cartes de sections */
@media (max-width: 767px) {
  .invest-solutions .section-card {
    width: 100%;
    margin: 10px 0;
  }

  /* Ajuster les paragraphes pour les petits écrans */
  .invest-solutions .advisory-container .textblock-advisory p,
  .invest-solutions .treasury-management-text p,
  .invest-solutions .treasury-management-sp-textblock p,
  .invest-solutions .treasury-management-amc-textblock p,
  .invest-solutions .portfolio-analysis-container .portfolio-analysis-header .text p,
  .invest-solutions .risk-management-container .risk-management-header .text p {
    width: 90%;
    margin: 0 auto;
    text-align: justify;
    line-height: 1.4;
    font-size: 16px;
  }

  .invest-solutions .advisory-container .textblock-advisory .sub-title,
  .invest-solutions .treasury-management-sp-textblock .sub-title,
  .invest-solutions .treasury-management-amc-textblock .sub-title {
    font-size: 14px;
    margin-bottom: 2rem;
  }

  /* Ajuster la présentation des images et aligner les sections */
  .invest-solutions .advisory-container img,
  .invest-solutions .treasury-management-sp-container img {
    width: 70%;
    margin: 20px auto;
    display: block;
  }

  /* Suppression du padding pour le treasury-management-amc-textblock sur mobile */
  .invest-solutions .treasury-management-amc-textblock {
    padding: 0;
    width: 100%;
    margin: 0 auto;
    text-align: justify;
  }

  /* Ajuster le texte des sections pour un meilleur affichage sur mobile */
  .invest-solutions .portfolio-analysis-container .portfolio-analysis-header .text,
  .invest-solutions .risk-management-container .risk-management-header .text {
    width: 90%;
    margin: 0 auto;
    text-align: justify;
    font-size: 16px;
    line-height: 1.4;
  }

  /* Ajuster les conteneurs pour s'adapter aux petits écrans */
  .invest-solutions .advisory-container,
  .invest-solutions .treasury-management-sp-container,
  .invest-solutions .treasury-management-amc-container,
  .invest-solutions .portfolio-analysis-container .portfolio-analysis-services,
  .invest-solutions .risk-management-container .risk-management-services {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
  }

  .invest-solutions .treasury-management-amc-container {
    width: 100%;
    margin-left: 0%;
    text-align: center;
    font-size: 16px;
  }

  .invest-solutions .treasury-management-amc-textblock .title {
    margin-left: 0%;
  }

  .invest-solutions .treasury-management-amc-container img {
    left: 0%;
  }
}
