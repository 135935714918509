/* Page principale */
.join-us {
  font-family: 'PPMori Bold';
  line-height: 1.6;
  margin: 0;
  padding: 0;
  color: #333;
}

/* Header avec l'image */
.join-us .header {
  position: relative;
  height: 510px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
  
.join-us .header .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.join-us .header .title {
  color: white;
  font-family: 'PPMori';
  padding-left: 71%;
  font-size: 2.8em;
  padding-top: 50px;
  z-index: 1; 
}

/* Conteneur avec les textes de la page */
.join-us .container {
  width: 80%;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  padding-top:3%;
  padding-bottom:5%;
}

/* Contenu de la page */
.join-us .main-content {
  flex: 2;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  text-align: justify;
}

  
.join-us .section {
  margin-bottom: 20px;
}
  
.join-us h2 {
  color: #333;
  text-align: center;
}

.join-us p {
  font-size: 45px;
  padding-top: 0px;
  padding-left: 10px;
  font-family: 'PPMori';
}

.join-us .section-title {
  color: #061237;
  font-family: 'PPMori';
  font-size: 30px;
  margin-right: 200px;
}
  
.join-us .section-text {
  color: black;
  font-family: PPMori;
  font-weight: normal;
  font-size: 20px;
  margin-right: 200px;
}

/* Contenu à droite de la page */
.join-us .sidebar {
  flex: 1;
  padding: 20px;
  background-color:rgb(230,231,235);
  border-radius: 10px;
  text-align: center;
}
  
.join-us .contact-title {
  color: #061237;
  font-family:'PPMori';
  font-size: 30px;
  text-align: center;
}
  
.join-us .contact-info {
  color: black;
  font-family: PPMori;
  font-weight: normal;
  font-size: 20px;
  align-items: center;
}
  
.join-us .footer {
  text-align: center;
}

.join-us .logoh {
  margin-bottom: 40px;
}

.join-us h6 {
  color: #3972E5 ;
  font-family: 'PPMori';
  font-size: 20px;
  text-align:center;
}

.join-us b {
  color: #061237 ;
  font-family: 'PPMori';
}

/* Pre-footer de la page avec la video dans le fond du logo */
.join-us .bottom-section {
  background-color: #061237;
  text-align: center;
}

.join-us .video_holder {
  width: 100%;
  height: auto;
  max-height: 100px;
  overflow: hidden;
}

.join-us .video_holder video {
  width: 100%;
  height: auto; 
}
/* Requêtes média pour le resizing de la page */
@media (max-width: 1300px) {
  .join-us .container {
    flex-direction: column;
    align-items: center;
  }

  .join-us .section-text {
    margin-right: 0px;
    text-align: center;
    font-size: 16px;

  }
  .join-us .main-content,
  .join-us .sidebar {
    width: 100%;
    margin: 0;
  }

  .join-us .main-content {
    margin-bottom: 20px;
  }
  .join-us .section-title,
  .join-us .section-subtitle {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .join-us .section-text {
    text-align: justify; /* Justification du texte sur mobile */
  }
}