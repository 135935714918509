/* Contenu de la page */
.your-team .content-team {
  padding-bottom: 100px;
}

.content-team{
  background-color: white;
}

/* Header avec image de fond */
.your-team .header {
  position: relative;
  height: 510px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.your-team .header .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Section de description de la page en haut */
.your-team .section-top{
  background-color: white;
  border-radius: 10px;
  width:80%;
  margin-left:10%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.your-team .section-title{
  text-align: center;
  font-size: 3em; 
  color: #061237;
  padding:20px;
}


.your-team .section-top .section-title .highlight {
  color: #3972E5; 
}

.your-team .section-description {
  text-align: center; 
  font-size: 1.2em;
  color:#666; 
  margin: 0 auto;
  width: 80%; 
  line-height: 1.8;
  padding-top: 1%; 
  padding-bottom: 3%;
}

/* Membres de l'équipe */
.your-team .content-team .team-member-container {
  display: flex;
  align-items: center;
  width: 99%;
  padding-top: 5%;
  margin-bottom: 3%;
  margin-left: 1%;
}

.your-team .content-team .team-member-container .photo {
  flex: 0 0 30%;
  margin-right: 20px; 
}

.your-team .content-team .team-member-container .photo img {
  width: 600px;
  height: auto;
  border-radius: 2%; 
  animation: 1s slidein-left;
}

.your-team .content-team .team-member-container .text {
  flex: 1;
  margin-left: 5%;
  animation: 1s slidein-right;
}

.your-team .content-team .team-member-container .member-name {
  font-size: 2.5em;
  color: #061237;
}

.your-team .content-team .team-member-container .member-function {
  font-size: 1.1em;
  color: #3972E5;
  font-style: italic;
  margin-bottom: 2%;
}


.your-team .content-team .team-member-container .member-description {
  font-size: 1.4em;
  color: #666;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  max-width: 85%;
  text-align: justify;
}

/* Pre-footer */
.your-team .bottom-section {
  background-color: #061237;
  text-align: center;
}

.your-team .video_holder {
  width: 100%;
  height: auto;
  max-height: 100px;
  overflow: hidden;
}

.your-team .video_holder video {
  width: 100%;
  height: auto;
}

/* Animations */
.your-team .content-team .team-member-container .photo,
.your-team .content-team .team-member-container .text .member-name,
.your-team .content-team .team-member-container .text .member-function {
  opacity: 0; 
  transition: opacity 1s ease-in-out, transform 1s ease-in-out; 
}

.your-team .content-team .team-member-container .photo.is-inViewport {
  opacity: 1; 
  transform: translateX(0); 
  animation: slidein-left 0.5s ease-out; 
}

.your-team .content-team .team-member-container .text .member-name.is-inViewport,
.your-team .content-team .team-member-container .text .member-function.is-inViewport{
  opacity: 1; 
  transform: translateX(0);
  animation: slidein-right 0.5s ease-out; 
}

/* Désactiver l'animation pour la description dans les conteneurs inversés */
.your-team .content-team .team-member-container.reverse .text .member-description.is-inViewport {
  animation: none; /* Aucune animation pour la description des membres */
  opacity: 1; /* Assurez-vous que la description est visible */
  transform: none; /* Réinitialiser la transformation */
}


.your-team .content-team .team-member-container.reverse .text .member-name,
.your-team .content-team .team-member-container.reverse .text .member-function {
  text-align:right;
  padding-right :170px;
}

.your-team .content-team .team-member-container.reverse {
  margin-left :0;
}

@keyframes slidein-left {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slidein-right {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Requêtes média pour resizing */
@media (max-width: 1300px) { 
  .your-team .section-top {
    margin-bottom: 15%;
  }

  .your-team .bottom-section {
    padding-right: 40px;
  }

  .your-team .video_holder {
    margin: 0 10px; 
  }

  .your-team .logo_mask {
    margin: 0 10px;
  }
}

.central-image-container {
  position: relative;
  display: flex; /* Utiliser Flexbox pour centrer */
  justify-content: center; /* Centrer horizontalement */
  width: 100%; /* Utilisez 100% de la largeur disponible */
  max-width: 80%; /* Limiter la largeur maximale à 80% de l'écran */
  margin: 0 auto; /* Centrer l'élément */
  overflow: hidden; /* Assurez-vous que rien ne dépasse */
}

.central-image {
  width: 100%; /* L'image prend toute la largeur du conteneur */
  max-width: 100%; /* Empêche l'image de dépasser la largeur du conteneur */
  height: auto; /* Conserver les proportions de l'image */
  margin: 0 auto; /* Assurez-vous que l'image est centrée */
  border-radius: 1%; /* Arrondir légèrement les coins */
}

.hotspots {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hotspot {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hotspot:hover {
  transform: scale(1.2);
}

.tooltip {
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  width: max-content;
  max-width: 200px;
  text-align: center;
  z-index: 10;
}

.hotspot:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.tooltip-name {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
}

.tooltip-role {
  font-size: 12px;
  color: #666;
}

@media (max-width: 768px) {
  .central-image-container {
    max-width: 100%; /* Utilisez 100% pour les petits écrans */
    margin: 0 auto; /* Centrer le conteneur sur mobile */
  }

  .central-image {
    width: 100%; /* S'assurer que l'image occupe toute la largeur sur mobile */
    height: auto;
    margin: 0 auto; /* Assurez-vous que l'image est centrée */
  }

  .hotspot {
    width: 15px;
    height: 15px;
  }

  .tooltip {
    padding: 8px;
    font-size: 12px;
  }

  .tooltip-name {
    font-size: 14px;
  }

  .tooltip-role {
    font-size: 10px;
  }
}

@media (max-width: 767px) {

  /* Conteneur de chaque membre de l'équipe */
  .your-team .team-member-container,
  .your-team .team-member-container.reverse {
    display: flex;
    flex-direction: column; /* Disposer en colonne */
    align-items: center; /* Centrer tous les éléments */
    text-align: center; /* Centrer le texte */
    width: 100%; /* Prendre toute la largeur disponible */
    margin-bottom: 30px; /* Espacement entre chaque membre */
    padding: 0 15px; /* Un peu de padding pour ne pas toucher les bords */
  }

  /* Centrer et redimensionner les images pour mobile */
  .your-team .team-member-container .photo img,
  .your-team .team-member-container.reverse .photo img {
    width: 90%; /* Utiliser 90% de la largeur du conteneur */
    height: auto; /* Garder le ratio d'aspect */
    margin: 0 auto; /* Centrer l'image */
    display: block; /* Assurer que l'image est un élément de bloc */
  }

  /* Centrer les noms et les titres */
  .your-team .team-member-container .member-name,
  .your-team .team-member-container .member-function,
  .your-team .team-member-container.reverse .member-name,
  .your-team .team-member-container.reverse .member-function {
    width: 90%; /* Prendre toute la largeur */
    margin-top: 15px; /* Espacement au-dessus du nom */
    text-align: center; /* Centrer le texte */
    padding-right: 0;
  }

  /* Justifier et centrer les descriptions */
  .your-team .team-member-container .member-description,
  .your-team .team-member-container.reverse .member-description {
    line-height: 1.6; /* Améliorer la lisibilité avec un interligne plus élevé */
    width: 90%; /* Prendre 100% de la largeur du conteneur */
    margin: 15px auto; /* Centrer horizontalement avec un espace au-dessus et en dessous */
    text-align: justify; /* Justifier le texte */
  }

  /* Description de la section */
  .your-team .section-description {
    text-align: justify; /* Justifier le texte pour la lisibilité */
    line-height: 1.6; /* Améliorer la lisibilité */
    width: 90%; /* Utiliser 90% de la largeur disponible */
    margin: 0 auto; /* Centrer la description de la section */
  }

  .your-team .content-team .team-member-container .member-description,
  .your-team .content-team .team-member-container.reverse .member-description {
    font-size: 1em;
  }

  /* Réorganiser le conteneur inversé */
  .your-team .content-team .team-member-container.reverse {
    flex-direction: column-reverse;
    width: 90%;
  }

  .your-team .content-team .team-member-container.reverse .text .member-name,
  .your-team .content-team .team-member-container.reverse .text .member-function {
    text-align: center; /* Changer de right à center */
    padding-right: 0; /* Supprimer le padding */
  }

  .your-team .content-team .team-member-container .text .member-name,
  .your-team .content-team .team-member-container .text .member-function {
    text-align: center; /* Changer de right à center */
    padding-right: 0; /* Supprimer le padding */
  }
}