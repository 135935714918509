.navbar {
  background-color: #fff;
  position: fixed; 
  top: 0;
  width: 100%;
  z-index: 1000; 
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.1s ease;
  border-bottom: 1px solid grey;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
  display: flex;
  align-items: left;
  transition: transform 0.2s ease-in-out;
}

.navbar-logo:hover {
  transform: scale(1.02);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 15px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 1.1rem;
}

.nav-menu-right {
  margin-left: auto;
}

.nav-item {
  height: 80px;
  font-size: 1.1rem;
}

.nav-links {
  color: #061237;
  font-family: 'PPMori';
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: transform 0.2s ease-in-out;
}

.nav-links:hover {
  transform: scale(1.05);
}

.language-toggle {
  cursor: pointer;
}

.language-toggle-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbar {
  background-color: #fff;
  position: fixed; 
  top: 0;
  width: 100%;
  z-index: 1000; 
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.1s ease;
  border-bottom: 1px solid grey;
}

.navbar-container {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  height: 60px;
  padding-left: 8%;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
  display: flex;
  align-items: left;
  transition: transform 0.2s ease-in-out;
}

.navbar-logo:hover {
  transform: scale(1.02);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 15px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 1.1rem;
}

.nav-menu-right {
  margin-left: auto;
}

.nav-item {
  height: 80px;
  font-size: 1.1rem;
}

.nav-links {
  color: #061237;
  font-family: 'PPMori';
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: transform 0.2s ease-in-out;
}

.nav-links:hover {
  transform: scale(1.05);
}

.language-toggle {
  cursor: pointer;
}

.language-toggle-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1300px) {
  .navbar {
    padding: 10px;
  }

  .navbar-container {
    padding-left: 0;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow-y: auto;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
    color: #061237; /* Toujours bleu marine */
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 1001;
  }

  .menu-icon i {
    color: #061237; /* Couleur par défaut */
    transition: color 0.3s ease;
  }

  .navbar.transparent .menu-icon i {
    color: #fff; /* Bouton blanc quand la navbar est transparente */
  }

  /* S'assurer que les liens du menu sont toujours bleu marine, même si la navbar est transparente */
  .navbar.transparent .nav-menu .nav-links {
    color: #061237; /* Toujours bleu marine, même quand la navbar est transparente */
  }

  .language-toggle {
    display: none;
  }

  .language-toggle-mobile {
    display: block;
    text-align: center; /* Centre le contenu dans la section */
  }

  .language-toggle-mobile .nav-links {
    justify-content: center; /* Centre les éléments à l'intérieur */
  }

  .language-toggle-mobile .nav-links img {
    margin-right: 0; /* Supprimer la marge */
    display: block;
    margin-left: auto;
    margin-right: auto; /* Centrer l'image du drapeau */
  }

  .language-toggle-mobile .nav-links span {
    display: none; /* Masquer le texte "Français/Anglais" */
  }
}

.navbar.transparent {
  background: transparent;
  border-bottom: 0px;
}

.navbar.transparent ul li a {
  color: white;
}

.navbar.transparent .nav-menu.active .nav-links {
  color: #061237; /* Forcer la couleur bleu marine lorsque la navbar est transparente et le menu est ouvert */
}

.navbar.transparent .navbar-logo-transparent {
  pointer-events: none;
}

.navbar:not(.transparent) .navbar-logo:hover {
  transform: none !important;
}

/* Ajustements spécifiques pour iPhone */
@media screen and (max-width: 767px) {
  .navbar-logo img {
    height: 40px;
    width: auto;
  }

  .menu-icon {
    font-size: 1.5rem;
  }

  .nav-item {
    height: auto;
  }

  .nav-links {
    padding: 1rem;
  }
}
